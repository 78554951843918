import "./styles.css";
import p5 from "p5";
import { Shape } from "./services/shape";

const INITIAL_SHAPES = 5;
const SPAWN_COUNT = 3;
const MAX_CHILDREN = 3;
const totalPossibleChildren = Math.pow(SPAWN_COUNT, MAX_CHILDREN);

const appElement = document.getElementById("app");
appElement.style.display = "flex";
const animationElement = document.getElementById("animation");
let shapes = [];
let spawnDeleteCount = 0;
const appP5 = new p5(p => {
  p.setup = function() {
    p.angleMode(p.DEGREES);
    p.rectMode(p.CENTER);
    p.ellipseMode(p.CENTER);
    p.createCanvas(window.innerWidth, window.innerHeight);
    p.noStroke();
    for (let i = 0; i < INITIAL_SHAPES; i++) {
      shapes.push(new Shape(p));
    }
  };
  p.draw = function() {
    p.background(255);

    let newShapes = [];
    for (let i = 0; i < shapes.length; i++) {
      const shape = shapes[i];
      if (!shape.isOutOfBounds()) {
        shape.update().render();
        continue;
      }
      shapes.splice(i, 1);
      i--;
      if (shape.spawnCount >= MAX_CHILDREN) {
        spawnDeleteCount++;
        continue;
      }
      for (let s = 0; s < SPAWN_COUNT; s++) {
        const childShape = new Shape(p, shape.settings());
        childShape.render();
        newShapes.push(childShape);
      }
    }
    if (spawnDeleteCount / totalPossibleChildren >= 1) {
      const addShapes = Math.floor(spawnDeleteCount / totalPossibleChildren);
      for (let s = 0; s < addShapes; s++) {
        shapes.push(new Shape(p));
      }
      spawnDeleteCount = spawnDeleteCount % totalPossibleChildren;
    }
    shapes = [...shapes, ...newShapes];
  };
}, animationElement);
