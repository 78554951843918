export function randomIntegerBetween(from, to) {
  return Math.round(Math.ceil(Math.random() * (to - from)) + from);
}

export function randomArrayElement(arr) {
  if (!Array.isArray(arr) || arr.length === 0) {
    return null;
  }
  return arr[Math.floor(Math.random() * arr.length)];
}

export function randomVariance(num, varianceMax, forcePositive = null) {
  const variance = Math.random() * varianceMax;
  let variation;
  if (forcePositive) {
    variation = 1;
  } else if (forcePositive === false) {
    variation = -1;
  } else {
    variation = Math.random() > 0.5 ? 1 : -1;
  }
  return num * (1 + variation * variance);
}
