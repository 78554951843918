import {
  randomIntegerBetween,
  randomArrayElement,
  randomVariance
} from "./helpers";

// parameters that affect shape behavior
const AVAILABLE_SHAPES = ["ellipse", "rectangle", "triangle", "star"];
// identifiers for left, top, right, and bottom walls respectively
const WALLS = [1, 2, 3, 4];

// how big initial shapes are
const INITIAL_SIZE_FACTOR = 150;
// how much to vary lengths of a shape
const SHAPE_LENGTHS_VARIANCE = 0.4;
// how much to increase or decrease child size factors
const CHILD_SIZE_CHANGE = 0.5;

// how fast an initial shape will go, pixels per frame
const INITIAL_SPEED = 5;
// how much to vary that initial speed
const INITIAL_SPEED_VARIANCE = 0.25;

// how much faster or slower a child will move than its parent
const CHILD_SPEED_CHANGE = 1.4;
// how much to vary that speed change
const CHILD_SPEED_CHANGE_VARIANCE = 0.25;

// how fast a shape rotates, pixels per frame
const ROTATION_SPEED = 5;
// how much to vary that rotation
const ROTATION_SPEED_VARIANCE = 0.9;

export class Shape {
  constructor(p, parentSettings = null) {
    // the constructed p5 drawing object
    this.p = p;

    // the shape to draw as
    this.shape = parentSettings
      ? parentSettings.shape
      : randomArrayElement(AVAILABLE_SHAPES);

    // determines how many times it has previously spawned from
    this.spawnCount = parentSettings ? parentSettings.spawnCount + 1 : 0;

    // how fast the shape will move
    this.speed = parentSettings
      ? randomVariance(
          parentSettings.speed * CHILD_SPEED_CHANGE,
          CHILD_SPEED_CHANGE_VARIANCE
        )
      : randomVariance(INITIAL_SPEED, INITIAL_SPEED_VARIANCE);

    this.start = parentSettings
      ? { ...parentSettings.end }
      : this.wallCoordinates();
    this.coordinates = { ...this.start };
    const startWall = this.wallFromCoordinates(this.coordinates);
    this.end = this.wallCoordinates(startWall);

    // the angle of the shape's trajectory, in radians
    this.angle = Math.atan2(
      this.end.y - this.coordinates.y,
      this.end.x - this.coordinates.x
    );

    this.sizeFactor = parentSettings
      ? parentSettings.sizeFactor * CHILD_SIZE_CHANGE
      : INITIAL_SIZE_FACTOR;
    this.width = randomVariance(this.sizeFactor, SHAPE_LENGTHS_VARIANCE);
    this.height = randomVariance(this.sizeFactor, SHAPE_LENGTHS_VARIANCE);

    this.rotation = 0;
    const rotationDirection = Math.random() > 0.5 ? 1 : -1;
    this.rotationSpeed =
      randomVariance(ROTATION_SPEED, ROTATION_SPEED_VARIANCE) *
      rotationDirection;

    this.color = {
      r: randomIntegerBetween(1, 255),
      g: randomIntegerBetween(1, 255),
      b: randomIntegerBetween(1, 255)
    };
    this.moved = false;
  }

  isOutOfBounds() {
    return (
      this.moved &&
      (this.coordinates.x <= 0 ||
        this.coordinates.x >= this.p.width ||
        this.coordinates.y <= 0 ||
        this.coordinates.y >= this.p.height)
    );
  }

  update() {
    this.coordinates.x = this.coordinates.x + this.speed * Math.cos(this.angle);
    this.coordinates.y = this.coordinates.y + this.speed * Math.sin(this.angle);
    this.rotation += this.rotationSpeed;
    this.moved = true;

    return this;
  }

  render() {
    this.p.push();
    this.p.fill(this.color.r, this.color.g, this.color.b);
    this.p.translate(this.coordinates.x, this.coordinates.y);
    this.p.rotate(this.rotation);
    switch (this.shape) {
      case "ellipse":
        this.p.ellipse(0, 0, this.width, this.height);
        break;
      case "rectangle":
        this.p.rect(0, 0, this.width, this.height);
        break;
      case "triangle":
        this.p.triangle(
          -(this.width / 2),
          -(this.height / 2),
          0,
          this.height / 2,
          this.width / 2,
          this.height / 2
        );
        break;
      case "star":
        let angle = this.p.TWO_PI / 5;
        let halfAngle = angle / 2.0;
        let radius1 = this.sizeFactor / 5;
        let radius2 = this.sizeFactor / 2;
        this.p.beginShape();
        for (let a = 0; a < this.p.TWO_PI; a += angle) {
          let sx = 0 + Math.cos(a) * radius2;
          let sy = 0 + Math.sin(a) * radius2;
          this.p.vertex(sx, sy);
          sx = 0 + Math.cos(a + halfAngle) * radius1;
          sy = 0 + Math.sin(a + halfAngle) * radius1;
          this.p.vertex(sx, sy);
        }
        this.p.endShape(this.p.CLOSE);
        break;
      default:
        throw new Error("Invalid shape: " + this.shape);
    }
    this.p.pop();

    return this;
  }

  settings() {
    return {
      shape: this.shape,
      spawnCount: this.spawnCount,
      speed: this.speed,
      coordinates: { ...this.coordinates },
      sizeFactor: this.sizeFactor,
      end: { ...this.end }
    };
  }

  wallCoordinates(avoidWall = null) {
    const availableWalls = avoidWall
      ? WALLS.filter(wall => wall !== avoidWall)
      : WALLS;
    const wall = randomArrayElement(availableWalls);

    return this.randomWallCoordiates(wall);
  }

  wallFromCoordinates(coordinates) {
    if (coordinates.x <= 0) {
      return 1;
    }
    if (coordinates.x >= this.p.width) {
      return 3;
    }
    if (coordinates.y <= 0) {
      return 2;
    }
    if (coordinates.y >= this.p.height) {
      return 4;
    }
  }

  randomWallCoordiates(wall) {
    const coordiates = {};
    if (wall % 2 === 0) {
      coordiates.x = randomIntegerBetween(0, this.p.width);
      coordiates.y = wall === 2 ? 0 : this.p.height;
      return coordiates;
    }
    coordiates.x = wall === 1 ? 0 : this.p.width;
    coordiates.y = randomIntegerBetween(0, this.p.height);
    return coordiates;
  }
}
